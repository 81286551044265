export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Anasayfa',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Ürünler',
        route: '/products',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Ürün listesi',
            to: '/products'
          },
          {
            name: 'Ürün ekle',
            to: '/products/create'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Siparişler',
        route: '/orders',
        icon: 'cil-basket',
        items: [
          {
            name: 'Siparişler',
            to: '/orders/list'
          },
          {
            name: 'Kargo Bekleyenler',
            to: '/orders/waiting-for-shipment'
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Sistem Ayarları',
        route: '/settings',
        icon: 'cil-settings',
        items: [
          {
            name: 'E-Posta Şemaları',
            to: '/settings/mail/list',
          },
          // {
          //   name: 'E-Posta Şeması Oluştur',
          //   to: '/settings/mail/create',
          // },
          {
            name: 'E-Posta Gönder',
            to: '/settings/mail/send',
          },
          {
            name: 'Gönderilmiş E-Postalar',
            to: '/settings/sended-mail/list'
          }
        ]
      },
    ]
  }
]